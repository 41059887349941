import React from 'react'
import FacultyList from '../Components/Faculty_list'

const Faculty = ({setId}) => {
    return (
        <>
        <FacultyList setId={setId}/>
        </>
    )
}

export default Faculty