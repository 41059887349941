export const Fdp = [
    {
        id:1,
        topic: "Scope and Opportunities of Analog VLSI Design in Industry and Academia: Concepts to Full Custom Chip Design",
        date: "21st – 25th September 2020",
    },
    {
        id:2,
        topic: "ADVANCED ENGINEERING MATHEMATICS",
        date: "17th – 19th September 2020",
    },
]