export const Alumni = [

    {
        year: "2016-2018",
        group_name: "Alpha Batch",
        students: [
            {
                name: "Amit Kumar Singh",
                description: "VLSI Industry"
            },
            {
                name: "Abhijit Narayan Eshore",
                description: "PhD IITKGP"
            },
            {
                name: "Abhijit Das",
                description: "CSIR-CGCRI"
            },
            {
                name: "Sushmita",
                description: "PhD CU"
            },
            {
                name: "Sulagna Chakraborty",
                description: "PhD CU"
            },
            {
                name: "Sayantika Das",
                description: "Sankalp Semiconductor"
            },
            {
                name: "Pallavi Bharati",
                description: "DST Project, phd MAKAUT"
            },
            {
                name: "Avijit Kumar Paul",
                description: "TCS"
            },
            {
                name: "Arunima Mondal",
                description: "TCS"
            }
        ]
    },
    {
        year: "2017-2019",
        group_name: "Beta Batch",
        students: [
            {
                name: "Saptaparna De",
                description: "RCI DRDO"
            },
            {
                name: "Sanjukta Mitra",
                description: "DRDO"
            },
            {
                name: "Agnibha Dasgupta",
                description: "BOSE"
            },
            {
                name: "Suryamoy Mondal",
                description: "PhD, ISM Dhanbad"
            },
            {
                name: "Putul Sahoo",
                description: "Sankalp Semiconductor"
            },
            {
                name: "Humayun Rahaman",
                description: "Gitanjali Solar"
            },
            {
                name: "Antalina Saha",
                description: "Jadavpur University, SRF"
            }
        ]
    },
    {
        year: "2018-2020",
        group_name: "Gamma Batch",
        students: [
            {
                name: "Tias Das",
                description: "RCTRC, Kalyani"
            },
            {
                name: "Shuvra Jyoti Bose",
                description: "PhD NITDGP"
            },
            {
                name: "Dipanjan Rana",
                description: "Research Felow - MAKAUT "
            },
            {
                name: "Magnolia Roy",
                description: "PhD, IITKGP"
            },
            {
                name: "Ajoyjit Ghosh",
                description: "Gitanjali Solar"
            }
        ]
    },
    {
        year: "2019-2021",
        group_name: "Delta Batch",
        students: [
            {
                name: "Benoy Majumdar",
                description: "TCS, System Engg."
            },
            {
                name: "Debolina Pramanik",
                description: "PhD IITKGP"
            },
            {
                name: "Subham Sen",
                description: "Guest Lecturer"
            },
            {
                name: "Chandani Kumari",
                description: "IECT, Embedded System"
            },
            {
                name: "Soumya Sengupta",
                description: "PhD NIT Rourkella"
            },
            {
                name: "Tathagata Ghose",
                description: "Capgemini"
            },
            {
                name: "Arkajaa Acharya",
                description: "Banking Sector"
            },
            {
                name: "Priyanka Ghosh",
                description: "Royal Research"
            }
        ]
    },
    {
        year: "2020-2022",
        group_name: "Epsilon Batch",
        students: [
            {
                name: "SOMA CHATTERJEE",
                description: "Mallabhum Instt of Technology "
            },
            {
                name: "SHRESTHA CHATTERJEE",
                description: "Capgemini"
            },
            {
                name: "AMIT SARKAR",
                description: "IIT Delhi"
            },
            {
                name: "DIPTANU JOARDAR",
                description: "Infosys"
            },
            {
                name: "PRASENJIT BISHAL",
                description: "Green Semiconductor Pvt. Ltd."
            },
            {
                name: "FARZANA KHATOON",
                description: "Fusionbpo Services"
            },
            {
                name: "SHWETA KUMARI",
                description: "PP Passchip Pvt Ltd "
            },
            {
                name: "MEGHNAD HALDER",
                description: "ICAR- Fisheries, Project Assistant"
            }
        ]

    },
    {
        year: "2021-2023",
        group_name: "2023 Passout Batch",
        students: [
            {
                name: "ARIJIT PATRA",
                description: "JRF, C2S project, MAKAUT, WB "
            },
            {
                name: "PRATIP CHAKRABORTY",
                description: "PhD, IIT KGp"
            },
            {
                name: "RUPA GURUNG",
                description: "Analog designer, Lemonflip Solutions Pvt. Ltd."
            },
            {
                name: "MADHURIMA PAL",
                description: "Analog designer, Lemonflip Solutions Pvt. Ltd."
            },
            {
                name: "GARGEE SARKAR",
                description: "JIS Scool of Polytechnic"
            },
            {
                name: "BISWAJIT KUNDU",
                description: "Seacom Engineering College"
            },
            {
                name: "SHUVABRATA DEY",
                description: "Adben Industries Pvt. Ltd"
            },
            {
                name: "SAIKAT PRAMANIK",
                description: "Kalyani Technoforge Ltd."
            }
        ]

    }
]